export default [
  {
    path: '/proyectos/:customerId',
    name: 'projects',
    component: () => import('@/views/projects/MyProjects.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/nuevo-proyecto/:customerId',
    name: 'newProjects',
    component: () => import('@/views/projects/NewProject.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/editar-proyecto/:projectId',
    name: 'editProject',
    component: () => import('@/views/projects/EditProject.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ingresos/:projectId',
    name: 'incomes',
    component: () => import('@/views/projects/Income.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/nuevo-ingreso/:projectId',
    name: 'newIncome',
    component: () => import('@/views/projects/NewIncome.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/editar-ingreso/:incomeId',
    name: 'editIncome',
    component: () => import('@/views/projects/EditIncome.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/egresos/:projectId',
    name: 'expenses',
    component: () => import('@/views/projects/Expenses.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/todos-los-egresos/:projectId',
    name: 'allExpenses',
    component: () => import('@/views/projects/AllExpenses.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/presupesto/:projectId',
    name: 'budget',
    component: () => import('@/views/projects/Budget.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/egresos/:projectId/:groupedExpenseId',
    name: 'expensesByGroup',
    component: () => import('@/views/projects/ExpensesByGroup.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/nuevo-egreso/:projectId',
    name: 'newExpense',
    component: () => import('@/views/projects/NewExpense.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/explosion-de-insumos/:projectId',
    name: 'groupedExpenses',
    component: () => import('@/views/projects/Supplies.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/agregar-explosion-de-insumos/:projectId',
    name: 'addGroupedExpenses',
    component: () => import('@/views/pos/POS.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
