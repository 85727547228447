import axios from '@axios'

const state = () => ({
  groupedExpenses: {
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
    data: [],
  },
  expenseTypeCatalogs: {
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
    data: [],
  },
  supply: {},
  expenseType: '',
  subExpenseType: {},
})

const getters = {
  groupedExpenses(state) {
    return state.groupedExpenses.data
  },
  expenseTypeCatalogs(state) {
    return state.expenseTypeCatalogs.data
  },
  supply(state) {
    return state.supply
  },
  expenseType(state) {
    return state.expenseType
  },
  subExpenseType(state) {
    return state.subExpenseType.data
  },
}

const mutations = {
  ADD_SUPPLY(state, file) {
    state.groupedExpenses.push(file)
  },
  SET_GROUPED_EXPENSES(state, payload) {
    state.groupedExpenses = payload
  },
  SET_EXPENSE_CATALOGS(state, payload) {
    state.expenseTypeCatalogs = payload
  },
  SET_SUPPLY(state, payload) {
    state.supply = payload
  },
  SET_EXPENSE_TYPE(state, payload) {
    state.expenseType = payload
  },
  SET_SUB_EXPENSE_TYPE(state, payload) {
    state.subExpenseType = payload
  },
}

const actions = {
  fetchGroupedExpenses(
    context,
    { projectId, pagination = { page: 1, per_page: 100, all: true } },
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/grouped_expenses', {
          params: {
            by_project: projectId,
            meta: { pagination },
          },
        })
        .then(response => {
          context.commit('SET_GROUPED_EXPENSES', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchGroupedExpensesWithExpenses(
    context,
    { projectId, pagination = { page: 1, per_page: 100, all: true } },
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/grouped_expenses/with_expenses', {
          params: {
            by_project: projectId,
            meta: { pagination },
          },
        })
        .then(response => {
          context.commit('SET_GROUPED_EXPENSES', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchExpensesTypeCatalogs(context, projectId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/projects/${projectId}/expense_type_catalogs`)
        .then(response => {
          context.commit('SET_EXPENSE_CATALOGS', response)
          resolve(response)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchSubExpenseTypeCatalog(context, { projectId, expenseTypeId }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/projects/${projectId}/expense_type_catalogs/${expenseTypeId}`)
        .then(response => {
          context.commit('SET_SUB_EXPENSE_TYPE', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  addExpensesTypeCatalogs(context, { projectId, expenseType }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/v1/projects/${projectId}/expense_type_catalogs`, {
          expense_type_catalog: expenseType,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  editExpensesTypeCatalogs(context, { projectId, expenseType }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/projects/${projectId}/expense_type_catalogs/${expenseType.id}`, {
          expense_type_catalog: expenseType,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  deleteExpensesTypesCatalog(context, { projectId, expenseTypeId }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/v1/projects/${projectId}/expense_type_catalogs/${expenseTypeId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  addGroupedExpenses(context, expense) {
    return new Promise((resolve, reject) => {
      axios
        .post('/v1/grouped_expenses', {
          grouped_expense: expense,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  deleteGropedExpense(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/v1/grouped_expenses/${id}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  editGroupedExpense(context, expense) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/grouped_expenses/${expense.id}`, {
          grouped_expense: expense,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  downloadGroupedExpenseReport(_, projectId) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/grouped_expenses/report.csv', {
          params: {
            by_project: projectId,
          },
        })
        .then(response => {
          const url = `data:text/csv;charset=UTF-8,${encodeURIComponent(
            response.data,
          )}`
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'explosion-de-insumos.csv') // or any other extension
          document.body.appendChild(link)
          link.click()

          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchSupply(context, supplyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/supplies/${supplyId}`)
        .then(response => {
          context.commit('SET_SUPPLY', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
