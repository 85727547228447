import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'
// eslint-disable-next-line
import store from '@/store'
// import { canNavigate } from '@/libs/acl/routeProtection'
import projectAbility from '@/libs/acl/projectAbility'

import auth from './routes/auth'
import billing from './routes/billing'
import categories from './routes/categories'
import projects from './routes/projects'
import quotes from './routes/quotes'
import payments from './routes/payments'
import shippings from './routes/shippings'
import suppliers from './routes/suppliers'
import branches from './routes/branches'
import products from './routes/products'
import customers from './routes/customers'
import users from './routes/users'
import settings from './routes/settings'
import purchaseOrders from './routes/purchaseOrders'
import reports from './routes/reports'
import invoices from './routes/invoices'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'suppliers' } },
    ...auth,
    ...categories,
    ...projects,
    ...quotes,
    ...payments,
    ...shippings,
    ...suppliers,
    ...branches,
    ...products,
    ...customers,
    ...users,
    ...settings,
    ...purchaseOrders,
    ...reports,
    ...invoices,
    ...billing,
  ],
})

router.beforeEach((to, from, next) => {
  const userData = getUserData()
  const isLoggedIn = isUserLoggedIn()

  if (to.meta.requiresAuth) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })
  }

  // TODO: Mandar a vista de token expired no login.
  if (isLoggedIn) {
    store.commit('auth/setCurrentUser', userData)
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser(userData || null))
  }
  if (to.name === 'quotes') {
    const ability = projectAbility(store.getters['projects/project'])
    if (ability.can('navigate', 'quotes') === false) {
      // router.push({
      //   name: from.name,
      //   params: { customerId: store.getters['auth/currenUser']?.scoped_solres[0]?.role_resource_id },
      // })
      router.go(-1)
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
