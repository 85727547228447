export default [
  {
    path: '/facturacion',
    name: 'billing',
    component: () => import('@/views/billing/BillingList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/nueva_razon',
    name: 'new-billing',
    component: () => import('@/views/billing/BillingForm.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
